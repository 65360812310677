<template>
  <div id='safetyMemorabilia'>
     
    <div class="Implement-add">
        <!-- <el-radio-group v-model="tabPosition" style="float:left" @change="tabChange">
        <el-radio-button label="1">材料设置</el-radio-button>
        <el-radio-button label="2">规格设置</el-radio-button>
    </el-radio-group> -->
      <div class="adds"
           @click="addfn(1)" v-if="tabPosition == 1">添加材料</div>
           <div v-else >
            <div class="adds" @click="blackClick()" style="float:left;margin-right:20px">返回</div>
            <div class="adds" @click="addfn(1)" style="float:left">添加规格</div>
           </div>
           
    </div>

    <div class="Implement-wrap" v-if="tabPosition == 1">
      <div class="Implement-title">
        <span class="title-01">序号</span>
        <span class="title-02">材料名称</span>
        <span  style="width:57.5%">添加时间</span>
        <span class="title-05">操作</span>
      </div>

      <div class="Implement-list"
         v-if="listdata.length"
           v-for='(item,index) in listdata'
           :key="index">
        <span class="title-01 textOverflowHide">{{index+1}}</span>
        <span class="title-02 textOverflowHide">{{item.materialName}}</span>
        <span class="textOverflowHide" style="width:57.5%">{{item.CreateTime.replace('T', ' ')}}</span>
        <span class="title-05 textOverflowHide">
          <i @click="editClick(2,item)">编辑</i>
          <i @click="specification(item)">规格设置</i>
          <i @click="delfnmaterialName(item)" style="color:red">删除</i>
        </span>
      </div>
      <div class="notdata" v-else>
        暂无数据
      </div>
    </div>

     <div class="Implement-wrap" v-else>
      <div class="Implement-title">
        <span class="title-01">序号</span>
        <span class="title-02">材料名称</span>
        <span class="title-03">规格型号</span>
        <span class="title-04">添加时间</span>
        <span class="title-05">操作</span>
      </div>

      <div class="Implement-list"
         v-if="listdata2.length"
           v-for='(item,index) in listdata2'
           :key="index">
        <span class="title-01 textOverflowHide">{{index+1}}</span>
        <span class="title-02 textOverflowHide">{{item.materialName}}</span>
        <span class="title-03 textOverflowHide">{{item.specificationsContent}}</span>
        <span class="title-04 textOverflowHide">{{item.CreateTime.replace('T', ' ')}}</span>
        <span class="title-05 textOverflowHide">
          <i @click="editClick2(2,item)">编辑</i>
          <i @click="delfn(item)" style="color:red">删除</i>
        </span>
      </div>
      <div class="notdata" v-else>
        暂无数据
      </div>
    </div>
    <div class="pages"
         v-if="total && total > pageSizes">
      <el-pagination @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-size="pageSizes"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>

    <addMaterialScience v-if="addPfalse"
                  :sqtype='sqtype'
                 :addPtitle='addPtitle'
                 :editdata='editdata'
                 :materiaId="materiaId"
                 @addPfalsefn='addPfalsefn'
                 @GetProjectBigEventList='GetProjectBigEventList'></addMaterialScience>
  <previewImg
    v-if="FileType"
    :activeurn="activeurn"
    @close="close"
  ></previewImg>
  </div>

</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage,ElMessageBox } from "element-plus";
import addMaterialScience from '@/components/addMaterialScience.vue'
import previewImg from '@/components/previewimg.vue'
import {Get_materiaPageList,Delete_materia,Get_specificationsPageList,Delete_specifications} from '@/js/safeDisclose'
import { getStorage } from "@/js/common";
import { baseUrl } from "@/js/util.js";
import {DelPictureList} from "@/js/indexApi.js";
export default {
  name: '',
  props: {

  },

  setup (props) {

    const state = reactive({
      FileType:false,
      tabPosition:'1',
      activeurn:'',
      baseUrl:baseUrl,
      total: 0,
      currentPage: 1,
      pageSizes: 10,
      addPfalse: false,
      addPtitle: '',
      listdata:[],
      listdata2:[],
      materiaId:'',
      materialName:'',
      editdata:'',
      sqtype:1,//材料：1，规格：2，
    });
    const cdata = getStorage("bscdata");

    const delDoc = (docs) => {
      // 删除文件
      DelPictureList(docs).then((res) => {
       
       
      });
    };

    const methods = {
      // 添加弹框
      addfn: (id,item) => {
        console.log(121212)
        // if(id==1){
        //    state.addPtitle = '添加';
        // }else if(id==2){
        //   state.addPtitle='编辑入库';
        //   state.editdata=item
        //   console.log(state.editdata,'state.editdata')
        // }
        if (state.tabPosition == 1) {
            state.addPtitle = '添加材料';
            state.sqtype = 1;
        }else{
            state.addPtitle = '添加规格';
            state.sqtype = 2;
        }
       
        state.addPfalse = !state.addPfalse;
      },
      blackClick(){
         state.tabPosition = 1;
        state.materiaId = '';
        state.materialName = '';
        methods.GetProjectBigEventList()
      },
      editClick:(id,item) =>{
      
            state.addPtitle = '编辑材料';
            console.log(state.addPtitle);
            state.editdata=item
        state.addPfalse = !state.addPfalse;
      },
      editClick2(id,item){
        state.addPtitle = '编辑规格';
        state.editdata=item;
        state.sqtype = 2;
        state.addPfalse = !state.addPfalse;
      },
      //添加弹框 关闭弹框
      addPfalsefn () {
        state.addPfalse = false;
      },
      close(){
         state.FileType = false;
      },
      // 列表获取分页数据
      handleCurrentChange: (val) => {
        state.currentPage = val;
        methods.GetProjectBigEventList()
      },

    // 列表
    
     GetProjectBigEventList:()=>{
        if (state.tabPosition == 1) {
            let datas={
          ProjectID:cdata.pid,
          pageIndex:state.currentPage,
          pageSize:state.pageSizes,
        }
        Get_materiaPageList(datas).then((res) => {
          // 获取材料
          console.log(res,'list');
          if (res.data.Code == 1) {
            state.listdata=res.data.Data.data;
            state.total=res.data.Data.totalCount.Value;
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });
        }else{
            let datas={
          materiaId:state.materiaId,
          pageIndex:state.currentPage,
          pageSize:state.pageSizes,
        }
        Get_specificationsPageList(datas).then((res) => {
          // 获取规格
          console.log(res,'list');
          if (res.data.Code == 1) {
            state.listdata2=res.data.Data.data;
            state.listdata2.forEach(element => {
                element.materialName = state.materialName;
            });
            state.total=res.data.Data.totalCount.Value;
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });
        }
        

      },
    //   删除材料
      dels(item){
         let datas={
          id:item.ID,
        }
        Delete_materia(datas).then((res) => {
          if (res.data.Code == 1) {
           ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            methods.GetProjectBigEventList()
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },
    //   删除规格
      delsSpecifications(item){
        let datas={
          id:item.ID,
        }
        Delete_specifications(datas).then((res) => {
          if (res.data.Code == 1) {
           ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            methods.GetProjectBigEventList()
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },

      specification(item){
        state.tabPosition = 2;
        state.materiaId = item.ID;
        state.materialName = item.materialName;
         methods.GetProjectBigEventList()
      },
        // 删除
      delfnmaterialName(item){
         ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            methods.dels(item)
          }).catch((error) => {
           

          })
       
      },
      delfn(item){
        ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            methods.delsSpecifications(item)
          }).catch((error) => {
           

          })
      },
      preview(item){
        state.FileType = !state.FileType;
        state.activeurn = item.ImgUrl
      },



    };


    onMounted(() => {
          methods.GetProjectBigEventList()
    });

    return {
      ...methods,
      ...toRefs(state)
    };

  },

  components: {
    addMaterialScience,
    previewImg
  }


}

</script>

<style lang='scss' scoped>
#safetyMemorabilia {
  margin: 0 30px;
  box-sizing: border-box;
  .Implement-add {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    box-sizing: border-box;
    .adds {
      width: 86px;
      height: 36px;
      background: #0083ff;
      border-radius: 5px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  .Implement-title,
  .Implement-list {
    width: 100%;
    height: 40px;
    background: #e8f3f8;
    border: 1px solid #d1e2e5;
    font-size: 12px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #999999;
    display: inline-flex;
    align-items: center;
  }
  .Implement-list {
    background: transparent;
    height: 70px;
    line-height: 70px;
    span {
      i {
        &:nth-child(1),
        &:nth-child(2) {
          color: #0083ff;
        }

        &:nth-child(3) {
          color: #e7111b;
        }
      }
    }
    .title-04 {
    //   display: flex;
    //   // padding: 0;
    //   justify-content: space-around;
    }
  }
  .Implement-list {
    border-top: none;
    overflow: hidden;
  }
  .Implement-title > span,
  .Implement-list > span {
    display: inline-block;
    line-height: 40px;
    padding-left: 1%;
    border-right: 1px solid #d1e2e5;
    box-sizing: border-box;
  }
  .Implement-list > span {
    line-height: 70px;
  }

  .title-01 {
    width: 5%;
  }
  .title-02 {
    width: 35%;
  }
  .title-03 {
    width: 35%;
  }
  .title-04 {
    width: 18%;
  }
  .title-05 {
    width: 20%;
  }


  .Implement-list > .title-02 {
    display: inline-block;
    line-height: 70px;
    width: 35%;
    height: 70px;
    position: relative;

    img {
      display: inline-block;
      width: 50px;
      height: 50px;
      -o-object-fit: contain;
      object-fit: contain;
      background: #d8d8d8;
      position: absolute;
      // left: 50%;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }

  .Implement-list > .title-05 {
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }

  .pages {
    margin-top: 20px;
    box-sizing: border-box;
    float: right;
  }
}
</style>