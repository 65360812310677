<template>
  <div class="addProjectsafe">
    <div class="addProjects-wrap">
      <div class="addProjects-top">
        <div>{{addPtitle}}</div>
        <img @click="closefn"
             src="@/assets/images/pop01.png"
             alt="" />
      </div>
      <div class="addProjects-content" v-if="sqtype == 1">

        <div class="addProjects-input">
          <span>材料名称</span>
          <div>
          <el-input v-model="inputs1"
                      style="width:100%"
                      maxlength='20'
                      show-word-limit
                      placeholder="请输入材料名称"></el-input>
          </div>
        </div>

      </div>

       <!-- <div class="addProjects-content" v-if="sqtype == 2" style="padding: 0 20px 20px;">

        <div class="addProjects-input">
          <span>材料名称</span>
          <div>
          <el-select style="width:100%" v-model="materialName" clearable placeholder="请选择材料名称">
                    <el-option
                    v-for="item in optionsMaterial"
                    :key="item.ID"
                    :label="item.materialName"
                    :value="item.ID">
                    </el-option>
                </el-select>
          </div>
        </div>

      </div> -->
      <div class="addProjects-content" v-else>

        <div class="addProjects-input">
          <span>规格型号</span>
          <div>
          <el-input v-model="inputs2"
                      style="width:100%"
                      placeholder="请输入规格型号"></el-input>
          </div>
        </div>

      </div>
     
      <div class="addProjects-bottom">
        <button @click="closefn">取消</button>
        <button v-if="sqtype == 1" @click="AddExtractApplyfn"
                :plain="true"
                class="btns"
                :class="(!inputs1)?'def':'' ">
          确定
        </button>
        <button v-else @click="AddExtractApplyfn"
                :plain="true"
                class="btns"
                :class="(!inputs2)?'def':'' ">
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import Ewang from "wangeditor";
import { baseUrl } from "@/js/util.js";

import { InsertOrUpdate_materia, InsertOrUpdate_specifications,getMateriaList} from '@/js/safeDisclose'
export default {
  props: {
    addPfalse: Boolean,
    bonus: Number,
    addPtitle: String,
    editdata: Object,
    sqtype: Number,
    materiaId:String,
  },
  setup (props, { emit }) {
    const state = reactive({
      newHtml: '',
      sqtype: '',
      optionsMaterial:[],
      options:[],
      editor: null,
      baseUrl: baseUrl,
      inputs1: "", //
      result:'',
      datevalue:'',
      inputs2: "",
      inputs3: "",
      value: "",
      Primarydata: [],
      Primaryobj: null,
      materialName:'',
      materiaId:'',
      adding: false,
      addPtitle: '',
      editid: '',
      num: 0,
      showstyle: false,
      filelist: [],
      fileimg: null,
      tableDatas: [
        {
          inputs1: "", //
          inputs2: "",
        }
      ],
    });
    const closefn = () => {
      emit("addPfalsefn");
    };

    const cdata = getStorage("bscdata");

    const methods = {
    //           getMateriaList: () =>{
    //     let params = {
    //       projectId:cdata.pid,
    //     }
    //     getMateriaList(params).then(res=>{
    //       if (res.data.Code == 1) {
    //         state.optionsMaterial = res.data.Data
    //       }
    //     })
    //   },

      // 添加材料
      UpdateSecurityBigEvent: () => {
        let datas = {}
        if (state.addPtitle == '编辑材料') {
          datas = {
            id: state.editid,
            materialName: state.inputs1,
            insertUser: cdata.uid,
            projectId: cdata.pid,
          }

        } else {
          datas = {

            materialName: state.inputs1,
            insertUser: cdata.uid,
            projectId: cdata.pid,
          }

        }

        InsertOrUpdate_materia(datas).then((res) => {

          console.log(res);
          if (res.data.Code == 1) {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            emit("addPfalsefn");
            emit("GetProjectBigEventList");
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });

      },

    //新增规格
      InsertOrUpdate_specifications:() =>{
         let datas = {}
        if (state.addPtitle == '编辑规格') {
          datas = {
            id: state.editid,
            materialId: state.materiaId,
            specificationsContent:state.inputs2,
            insertUser: cdata.uid,
            projectId: cdata.pid,
          }

        } else {
          datas = {
            materialId: state.materiaId,
            specificationsContent:state.inputs2,
            insertUser: cdata.uid,
            projectId: cdata.pid,
          }

        }

        InsertOrUpdate_specifications(datas).then((res) => {

          console.log(res);
          if (res.data.Code == 1) {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            emit("addPfalsefn");
            emit("GetProjectBigEventList");
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },

      AddExtractApplyfn: () => {
        
       
        if (state.sqtype == 1) {
             if (!state.inputs1) {
          return
        }
            methods.UpdateSecurityBigEvent()
        }else{
             if (!state.inputs2) {
          return
        }
            methods.InsertOrUpdate_specifications()
        }
        

      },



    };

    onMounted(() => {
      console.log(props)
     
      state.addPtitle = props.addPtitle;
      state.materiaId = props.materiaId;
      if (state.sqtype == 2) {
        // methods.getMateriaList();
      }
      if (state.addPtitle == '编辑材料') {
        state.editid = props.editdata.ID;
        state.inputs1 = props.editdata.materialName;
      }
       if (state.addPtitle == '编辑规格') {
        state.editid = props.editdata.ID;
        
        state.inputs2 = props.editdata.specificationsContent;
        console.log(state.inputs2);
      }
     state.sqtype = props.sqtype;
    });
    onBeforeUnmount(() => {
    });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addProjectsafe {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addProjects-wrap {
    width: 656px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addProjects-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }

  .addProjects-content {
    width: 100%;
    padding: 0 20px 60px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addProjects-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      &:deep(.img-list) {
        width: 81px;
        height: 33px;
        .el-upload-list--picture-card {
          .el-upload-list__item {
            width: 81px;
            height: 63px;
            border-radius: 0;
          }
          .el-upload-list__item-actions:hover
            span.el-upload-list__item-preview {
            display: none;
          }
        }
        .el-upload--picture-card {
          width: 81px;
          height: 63px;
          position: relative;
          background: #f3f3f3;
          border: 1px dashed #999999;
          border-radius: 0;
          .up-icon {
            width: 34px;
            height: 35px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          i {
            position: absolute;
            right: 12px;
            bottom: 8px;
            font-size: 13px;
            color: #999999;
            display: inline-block;
            height: 20px;
            line-height: 20px;
          }
        }

        .hiddenBtn {
          .el-upload--picture-card {
            display: none;
          }
        }
      }
      .list-title {
        position: absolute;
        left: 186px;
        bottom: 2px;
      }

      span {
        display: inline-block;
        width: 90px;
        padding-right: 11px;
        box-sizing: border-box;
        text-align: right;
      }
      div {
        width: 337px;
      }
    }
    .addProjects-input02 {
      width: 100%;
      div {
        width: 500px;
      }

      #div1 .w-e-toolbar {
        z-index: 100 !important;
      }
      #div1 .w-e-text-container {
        z-index: 99 !important;
        height: 150px !important;
      }
    }
    &:deep(#div1) {
        .w-e-text{
           p{
          b{
            font-weight: bold !important;
            }
          i{
            font-style:italic !important;
            }
      }
        }
       
    }

    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addProjects-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addProjects-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;

      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      &:nth-child(2) {
      }
    }

    .btns {
      background: #027aff;
      color: #fff;
    }

    .def {
      background: rgb(239, 239, 239);
      color: #adbaae;
      cursor: default;
    }
  }
}
</style>